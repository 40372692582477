@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  visibility: hidden; /* doesn't seem to work */
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #E0E0E0;
}

*::-webkit-scrollbar:window-inactive {
  visibility: hidden;
}

